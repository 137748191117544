import { Action, AppState } from "./AppProvider";

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case "TOGGLE_CHAT_HISTORY":
            return { ...state, isChatHistoryOpen: !state.isChatHistoryOpen };
        case "UPDATE_CURRENT_CHAT":
            return { ...state, previousChat: state.currentChat, currentChat: action.payload };
        case "UPDATE_CHAT_HISTORY_LOADING_STATE":
            return { ...state, chatHistoryLoadingState: action.payload };
        case "UPDATE_CHAT_HISTORY":
            if (!state.chatHistory || !state.currentChat) {
                return state;
            }
            let conversationIndex = state.chatHistory.findIndex(conv => conv.id === action.payload.id);
            if (conversationIndex !== -1) {
                let updatedChatHistory = [...state.chatHistory];
                updatedChatHistory[conversationIndex] = state.currentChat;
                return { ...state, chatHistory: updatedChatHistory };
            } else {
                return {
                    ...state,
                    chatHistory: [...state.chatHistory, action.payload],
                    historyCurrentCount: state.historyCurrentCount + 1,
                    historyCount: state.historyCount + 1
                };
            }
        case "UPDATE_CHAT_TITLE":
            if (!state.chatHistory) {
                return { ...state, chatHistory: [] };
            }
            let updatedChats = state.chatHistory.map(chat => {
                if (chat.id === action.payload.id) {
                    if (state.currentChat?.id === action.payload.id) {
                        state.currentChat.title = action.payload.title;
                    }
                    //TODO: make api call to save new title to DB
                    return { ...chat, title: action.payload.title };
                }
                return chat;
            });
            return { ...state, chatHistory: updatedChats };
        case "DELETE_CHAT_ENTRY":
            if (!state.chatHistory) {
                return { ...state, chatHistory: [] };
            }
            let filteredChat = state.chatHistory.filter(chat => chat.id !== action.payload);
            state.currentChat = null;
            //TODO: make api call to delete conversation from DB
            return { ...state, chatHistory: filteredChat };
        case "DELETE_CHAT_HISTORY":
            //TODO: make api call to delete all conversations from DB
            return { ...state, chatHistory: [], filteredChatHistory: [], currentChat: null };
        case "DELETE_CURRENT_CHAT_MESSAGES":
            //TODO: make api call to delete current conversation messages from DB
            if (!state.currentChat || !state.chatHistory) {
                return state;
            }
            const updatedCurrentChat = {
                ...state.currentChat,
                messages: []
            };
            return {
                ...state,
                currentChat: updatedCurrentChat
            };
        case "FETCH_CHAT_HISTORY":
            return { ...state, chatHistory: action.payload };
        case "SET_COSMOSDB_STATUS":
            return { ...state, isCosmosDBAvailable: action.payload };
        case "UPDATE_ASSISTANT":
            return { ...state, assistant: action.payload };
        case "UPDATE_ASSISTANT_BY_TYPE":
            if (!state.assistants) {
                return state;
            }
            let firstAssistant = state.assistants.filter(assistant => assistant.type === action.payload)[0];
            return { ...state, assistant: firstAssistant };
        case "FETCH_ASSISTANTS":
            return { ...state, assistants: action.payload };
        case "UPDATE_ASSISTANTS_LOADING_STATE":
            return { ...state, assistantsLoadingState: action.payload };
        case "TOGGLE_ANSWER_LOADING":
            return { ...state, answerLoading: !state.answerLoading };
        case "SET_ANSWER_LOADING":
            return { ...state, answerLoading: action.payload };
        case "UPDATE_SELECTED":
            return { ...state, selectedAssistant: action.payload };
        case "UPDATE_CHECKED":
            return { ...state, checkedAssistant: action.payload };
        case "SET_HISTORY_COUNT":
            return { ...state, historyCount: action.payload };
        case "SET_HISTORY_CURRENT_COUNT":
            return { ...state, historyCurrentCount: action.payload };
        case "FETCH_MORE_CHAT_HISTORY":
            if (!state.chatHistory) {
                return { ...state, chatHistory: [] };
            }
            if (action.payload) {
                return {
                    ...state,
                    chatHistory: [...state.chatHistory, ...action.payload],
                    historyCurrentCount: state.chatHistory.length + action.payload.length
                };
            } else {
                return state;
            }
        case "UPDATE_CHAT_HISTORY_MORE_LOADING_STATE":
            return { ...state, chatHistoryMoreLoadingState: action.payload };
        case "SET_LOGIN_EXPIRED":
            return { ...state, loginExpired: true };
        case "SET_URL_ASSISTANT":
            return { ...state, urlAssistant: action.payload };
        case "ADD_FILE":
            return {
                ...state,
                files: [
                    ...state.files,
                    {
                        file: { name: action.payload },
                        text: "",
                        status: "Loading",
                        new: true
                    }
                ]
            };
        case "ADD_CHAT_HISTORY_FILE":
            return {
                ...state,
                files: [
                    ...state.files,
                    {
                        file: { name: action.payload.name },
                        text: action.payload.text,
                        status: "Success",
                        new: false
                    }
                ]
            };
        case "UPDATE_FILE_TEXT":
            return {
                ...state,
                files: state.files.map(fileItem => (fileItem.file.name === action.payload.name ? { ...fileItem, text: action.payload.text } : fileItem))
            };
        case "UPDATE_FILE_STATUS":
            return {
                ...state,
                files: state.files.map(fileItem => (fileItem.file.name === action.payload.name ? { ...fileItem, status: action.payload.status } : fileItem))
            };
        case "REMOVE_FILE":
            return {
                ...state,
                files: state.files.filter((_, i) => i !== action.payload)
            };
        case "REMOVE_ALL_FILES":
            return {
                ...state,
                files: []
            };
        case "UPDATE_ALL_FILES_NEW":
            return {
                ...state,
                files: state.files.map(fileItem => ({ ...fileItem, new: false }))
            };
        case "UPDATE_TOKEN":
            return { ...state, token: action.payload };
        default:
            return state;
    }
};
