import { useContext } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { AppStateContext } from "../../state/AppProvider";
import { Sidebar } from "../Sidebar";

interface Props {
    onNewClick: () => void;
    newDisabled?: boolean;
    onHistoryClick: () => void;
}

export const MobileSidebar = ({ onNewClick, newDisabled, onHistoryClick }: Props) => {
    const appStateContext = useContext(AppStateContext);

    const setOpen = () => {
        appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
    };

    return (
        <Transition show={appStateContext?.state.isChatHistoryOpen}>
            <Dialog className="relative z-10" onClose={setOpen}>
                <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 opacity-100" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[320px]">
                            <TransitionChild
                                enter="transform transition ease-in-out duration-300"
                                enterFrom="translate-x-[-100%]"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-[-100%]"
                            >
                                <DialogPanel className="pointer-events-auto relative w-screen max-w-md bg-jtc-grey">
                                    <Sidebar onNewClick={onNewClick} newDisabled={newDisabled} onHistoryClick={onHistoryClick} />
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
