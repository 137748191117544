import { LoginButton } from "../../components/LoginButton";

const LoginPage = () => {
    return (
        <div className="login-background relative w-screen h-screen overflow-hidden">
            <div className="absolute top-[61%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 align-center z-[1]">
                <LoginButton />
            </div>
        </div>
    );
};

export default LoginPage;
