import { animated, useSpring } from "@react-spring/web";

import { AnswerIcon } from "./AnswerIcon";

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <div className="w-full">
            <div className="py-[18px] px-4 text-base m-auto ">
                <div className="mx-auto flex flex-1 text-base gap-4 lg:gap-6 lg:max-w-3xl bg-jtc-white opacity-95 rounded-lg p-5">
                    <div className="flex-shrink-0 flex flex-col relative items-end">
                        <div>
                            <div className="pt-0">
                                <div className="outline outline-2 outline-jtc-purple flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative p-1 pt-1.5 rounded-sm flex items-center justify-center h-8 w-8 text-[#000000]">
                                        <AnswerIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex w-full min-w-0 flex-col">
                        <div className="flex-col gap-1 lg:gap-3">
                            <div className="flex flex-grow flex-col max-w-full">
                                <div className="flex flex-col gap-1 lg:gap-3">
                                    <div className="flex flex-grow flex-col max-w-full">
                                        <div className="min-h-[20px] text-message flex flex-col whitespace-pre-wrap break-words w-full items-end overflow-x-auto gap-2">
                                            <div className="flex w-full flex-col gap-1 first:pt-[3px]">
                                                <div className="w-full break-words">
                                                    <animated.div style={{ ...animatedStyles }}>
                                                        <p className="">
                                                            Generating answer
                                                            <span className="loading-dots" />
                                                        </p>
                                                    </animated.div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
