import { ChatHistoryPanel } from "../ChatHistory";
import { UserButton } from "../UserButton";
import { ButtonTooltip } from "../ButtonTooltip";

interface Props {
    onNewClick: () => void;
    newDisabled?: boolean;
    onHistoryClick: () => void;
}

export const Sidebar = ({ onNewClick, newDisabled, onHistoryClick }: Props) => {
    return (
        <nav className={"flex h-full w-full flex-col px-3 pb-0"}>
            <div className="flex justify-between flex h-16 items-center">
                <ButtonTooltip title="Hide history">
                    <span className="">
                        <a className="h-10 rounded-lg px-1 text-jtc-purple cursor-pointer hover:text-jtc-blue" onClick={onHistoryClick}>
                            <span className="h-[24px] w-[24px] shrink-0 text-2xl material-symbols-outlined">left_panel_close</span>
                        </a>
                    </span>
                </ButtonTooltip>
                {!newDisabled ? (
                    <ButtonTooltip title="New chat">
                        <span className="">
                            <a className={"h-10 rounded-lg px-1 cursor-pointer text-jtc-purple hover:text-jtc-blue"} onClick={onNewClick}>
                                <span className="h-[24px] w-[24px] shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                            </a>
                        </span>
                    </ButtonTooltip>
                ) : (
                    <span className="">
                        <a className={"h-10 rounded-lg px-1 text-jtc-disabled cursor-default"} onClick={onNewClick}>
                            <span className="h-[24px] w-[24px] shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                        </a>
                    </span>
                )}
            </div>
            <div className="flex-col flex-1 transition-opacity duration-500 -mr-2 pr-2 overflow-y-auto overflow-x-hidden">
                <div className="flex flex-col gap-2 pb-2 text-sm">
                    <ChatHistoryPanel />
                </div>
            </div>
            <div className="flex p-2">
                <UserButton />
            </div>
        </nav>
    );
};
