import React, { useState, useRef, useEffect, useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { AnimatePresence, motion } from "framer-motion";
import { Spinner, ISpinnerStyles } from "@fluentui/react";
import { ButtonTooltip } from "../ButtonTooltip";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    clearOnSend?: boolean;
    chatEmpty: boolean;
    windowWidth: number;
    fileInputRef?: React.RefObject<HTMLInputElement>;
    uploadFile?: () => void;
    showToEnd?: boolean;
    toEnd?: () => void;
}

export const QuestionInput = ({ onSend, disabled, clearOnSend, chatEmpty, windowWidth, fileInputRef, uploadFile, showToEnd, toEnd }: Props) => {
    const appStateContext = useContext(AppStateContext);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const placeholders = appStateContext?.state.assistant?.examples || [];

    const calculateRows = (): void => {
        const textArea = textAreaRef.current;
        if (textArea) {
            textArea.style.height = "auto";
            const scrollHeight = textArea.scrollHeight;
            textArea.style.height = `${scrollHeight}px`;
        }
    };

    // Recalculate rows whenever the text changes
    useEffect(() => {
        calculateRows();
    }, [textAreaRef?.current?.value]);

    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        calculateRows();
        if (!ev.target.value) {
            setQuestion("");
            vanishAndSubmit();
        } else {
            setQuestion(ev.target.value);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const [currentPlaceholder, setCurrentPlaceholder] = useState(0);

    useEffect(() => {
        let interval: any;
        const startAnimation = () => {
            interval = setInterval(() => {
                setCurrentPlaceholder(prev => (prev + 1) % placeholders.length);
            }, 3000);
        };
        startAnimation();
        return () => clearInterval(interval);
    }, [placeholders.length]);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const newDataRef = useRef<any[]>([]);

    const animate = (start: number) => {
        const animateFrame = (pos: number = 0) => {
            requestAnimationFrame(() => {
                const newArr = [];
                for (let i = 0; i < newDataRef.current.length; i++) {
                    const current = newDataRef.current[i];
                    if (current.x < pos) {
                        newArr.push(current);
                    } else {
                        if (current.r <= 0) {
                            current.r = 0;
                            continue;
                        }
                        current.x += Math.random() > 0.5 ? 1 : -1;
                        current.y += Math.random() > 0.5 ? 1 : -1;
                        current.r -= 0.05 * Math.random();
                        newArr.push(current);
                    }
                }
                newDataRef.current = newArr;
                const ctx = canvasRef.current?.getContext("2d");
                if (ctx) {
                    ctx.clearRect(pos, 0, 800, 800);
                    newDataRef.current.forEach(t => {
                        const { x: n, y: i, r: s, color: color } = t;
                        if (n > pos) {
                            ctx.beginPath();
                            ctx.rect(n, i, s, s);
                            ctx.fillStyle = color;
                            ctx.strokeStyle = color;
                            ctx.stroke();
                        }
                    });
                }
                if (newDataRef.current.length > 0) {
                    animateFrame(pos - 8);
                } else {
                    setQuestion("");
                    // setAnimating(false);
                }
            });
        };
        animateFrame(start);
    };

    const vanishAndSubmit = () => {
        const value = textAreaRef.current?.value || "";
        if (value && textAreaRef.current) {
            const maxX = newDataRef.current.reduce((prev, current) => (current.x > prev ? current.x : prev), 0);
            animate(maxX);
        }
    };

    const spinnerStyles: ISpinnerStyles = {
        circle: {
            borderColor: "#019dcd #019dcd #c2f1ff00 #c2f1ff00",
            height: "16px",
            width: "16px",
            border: "2px solid"
        }
    };

    const removeFile = (index: number) => {
        appStateContext?.dispatch({ type: "REMOVE_FILE", payload: index });
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const onUploadFileClick = () => {
        if (fileInputRef) {
            fileInputRef.current?.click();
        }
    };

    return (
        <div
            className={
                "transition-[padding] duration-200 text-base m-auto pl-[20px] pr-[29px] " +
                (appStateContext?.state.isChatHistoryOpen ? "" : "lg:pl-[20px] lg:pr-[29px]")
            }
        >
            {showToEnd && (
                <div
                    className={
                        "absolute left-0 right-0 z-[4] mx-auto w-[32px] h-[32px] animate-bounce rounded-full bg-jtc-white outline outline-2 outline-jtc-purple hover:outline-jtc-blue"
                    }
                    style={{ bottom: textAreaRef.current ? textAreaRef.current.scrollHeight + 40 : 99 }}
                >
                    <button className="flex justify-center items-center h-[32px] w-[32px] font-semibold text-jtc-purple hover:text-jtc-blue" onClick={toEnd}>
                        <span className="material-symbols-outlined text-[1.25rem]">keyboard_arrow_down</span>
                    </button>
                </div>
            )}
            <div className="mx-auto flex flex-1 text-base gap-4 lg:gap-6 lg:max-w-3xl">
                <div className="flex w-full items-center">
                    {appStateContext?.state.assistant?.assistant === "sp" && windowWidth >= 976 && (
                        <a
                            className="mr-6 max-w-sm min-w-72 flex-grow bg-jtc-white hover:text-jtc-white flex items-center justify-center h-[59px] rounded-lg"
                            href="https://joogle.jtcgroup.com/shared-ownership/tax-guidance/"
                            target="_blank"
                            style={{ flexBasis: "60%" }}
                        >
                            <div className="tracking-[0.15rem] text-jtc-purple hover:text-jtc-blue cursor-pointer font-semibold">
                                Click here for tax guidance
                            </div>
                        </a>
                    )}
                    <div className="overflow-hidden flex flex-col w-full flex-grow-1 relative rounded-lg bg-jtc-grey">
                        {appStateContext?.state.assistant?.attachments &&
                            appStateContext?.state.files &&
                            appStateContext?.state.files.length > 0 &&
                            appStateContext?.state.files
                                .filter(fileItem => fileItem.new)
                                .map((fileItem, index) => (
                                    <a className="gap-2 w-fit flex pl-3.5 pt-3.5 pb-1 items-center">
                                        {appStateContext?.state.files[index].status === "Loading" && (
                                            <span className="flex justify-center items-center h-[24px] w-[24px]">
                                                <Spinner style={{ display: "block", verticalAlign: "middle" }} styles={spinnerStyles} />
                                            </span>
                                        )}
                                        <span className={appStateContext?.state.files[index].status === "Error" ? "text-jtc-disabled" : "text-jtc-black"}>
                                            {(appStateContext?.state.files[index].status === "Error" ? "Unable to upload " : "") + fileItem.file.name}
                                        </span>
                                        <ButtonTooltip title="Remove file" placement="top">
                                            <button className={"flex justify-center font-semibold text-jtc-blue"} onClick={() => removeFile(index)}>
                                                <span className="material-symbols-outlined text-[1.25rem]">cancel</span>
                                            </button>
                                        </ButtonTooltip>
                                    </a>
                                ))}
                        <textarea
                            ref={textAreaRef}
                            className={
                                "m-0 w-full resize-none border-0 bg-transparent focus-visible:outline-0 focus:ring-0 focus-visible:ring-0 py-3.5 pr-12 max-h-[200px] min-h-[52px] text-jtc-black placeholder-[#7F7F7F]" +
                                (appStateContext?.state.assistant?.attachments ? " pl-12" : " pl-4")
                            }
                            rows={1}
                            placeholder={!chatEmpty ? "Send a message" : ""}
                            value={question}
                            onChange={onQuestionChange}
                            onKeyDown={onEnterPress}
                        ></textarea>
                        {appStateContext?.state.assistant?.attachments && (
                            <>
                                <input
                                    className="hidden"
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    ref={fileInputRef}
                                    onChange={uploadFile}
                                    multiple
                                    maxLength={2}
                                />
                                <div className="absolute bottom-[17px] left-3 h-[28px]">
                                    {disabled ? (
                                        <a className={"text-[1.75rem] material-symbols-outlined text-jtc-disabled cursor-default"}>attach_file</a>
                                    ) : (
                                        <ButtonTooltip title="Attach up to 2 files" placement="top">
                                            <a
                                                className={"text-[1.75rem] material-symbols-outlined cursor-pointer text-jtc-blue hover:text-jtc-purple"}
                                                onClick={onUploadFileClick}
                                            >
                                                attach_file
                                            </a>
                                        </ButtonTooltip>
                                    )}
                                </div>
                            </>
                        )}
                        <button
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                            type="submit"
                            className="absolute right-3 bottom-[11.5px] z-50 h-8 w-8 rounded-lg disabled:bg-jtc-disabled bg-jtc-purple transition duration-200 flex items-center justify-center"
                        >
                            <motion.svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="text-jtc-white h-4 w-4"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <motion.path
                                    d="M5 12l14 0"
                                    initial={{
                                        strokeDasharray: "50%",
                                        strokeDashoffset: "50%"
                                    }}
                                    animate={{
                                        strokeDashoffset: !sendQuestionDisabled ? 0 : "50%"
                                    }}
                                    transition={{
                                        duration: 0.3,
                                        ease: "linear"
                                    }}
                                />
                                <path d="M13 18l6 -6" />
                                <path d="M13 6l6 6" />
                            </motion.svg>
                        </button>
                        {!question && chatEmpty && (
                            <div className="absolute flex items-center rounded-lg pointer-events-none h-[59px] bottom-0 left-0 right-0">
                                <AnimatePresence mode="wait">
                                    <motion.p
                                        initial={{
                                            y: 5,
                                            opacity: 0
                                        }}
                                        key={`current-placeholder-${currentPlaceholder}`}
                                        animate={{
                                            y: 0,
                                            opacity: 1
                                        }}
                                        exit={{
                                            y: -15,
                                            opacity: 0
                                        }}
                                        transition={{
                                            duration: 0.3,
                                            ease: "linear"
                                        }}
                                        className={
                                            "text-left w-[calc(100%-2rem)] truncate text-[#7F7F7F]" +
                                            (appStateContext?.state.assistant?.attachments ? " pl-12" : " pl-4")
                                        }
                                    >
                                        {placeholders[currentPlaceholder]}
                                    </motion.p>
                                </AnimatePresence>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="relative px-2 py-2 text-center text-[12px] text-jtc-white leading-normal">
                <span className="lg:hidden inline-block">JTC accepts no liability for the accuracy, completeness or use of ChatJTC’s responses.</span>
                <span className="hidden lg:inline-block max-w-3xl">
                    ChatJTC, powered by OpenAI, provides information and assistance for JTC users but cannot guarantee accuracy, completeness, or non-bias, so
                    users should verify responses with reliable sources. JTC accepts no liability for the accuracy, completeness or use of ChatJTC’s responses.
                </span>
            </div>
        </div>
    );
};
