import { useMemo, useContext, useState } from "react";
import DOMPurify from "dompurify";
import { AppStateContext } from "../../state/AppProvider";

import { ChatResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { ButtonTooltip } from "../ButtonTooltip";

interface Props {
    answer: ChatResponse;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, citationTitle: string) => void;
    onThoughtProcessClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onCopyAnswerClicked: () => void;
    showFollowupQuestions?: boolean;
    showThoughts?: boolean;
    lastAnswer: boolean;
    windowWidth: number;
}

export const Answer = ({
    answer,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onCopyAnswerClicked,
    showThoughts,
    lastAnswer,
    windowWidth
}: Props) => {
    const appStateContext = useContext(AppStateContext);
    const thoughts = answer.choices[0].context.metadata?.thoughts;
    const messageContent = answer.choices[0].message.content;

    let streamingDot: string =
        isStreaming && lastAnswer
            ? " <span class='material-symbols-outlined text-dot text-jtc-purple' style=\"font-variation-settings: 'FILL' 1;\"> circle</span>"
            : "";

    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked, streamingDot), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedThoughts = DOMPurify.sanitize(thoughts!);

    const [copyIcon, setCopyIcon] = useState<string>("content_copy");

    function onThoughtsClicked() {
        onThoughtProcessClicked();
    }

    function onCopyClicked() {
        onCopyAnswerClicked();
        setCopyIcon("check");
        setTimeout(() => {
            setCopyIcon("content_copy");
        }, 2000);
    }

    return (
        <div className="w-full">
            <div className={"transition-[padding] duration-200 py-[18px] px-4 text-base m-auto " + (appStateContext?.state.isChatHistoryOpen ? "" : "")}>
                <div className="mx-auto flex flex-1 text-base gap-4 lg:gap-6 lg:max-w-3xl bg-jtc-white opacity-95 rounded-lg p-5">
                    <div className="flex-shrink-0 flex flex-col relative items-end">
                        <div>
                            <div className="pt-0">
                                <div className="outline outline-2 outline-jtc-purple flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative p-1 pt-1.5 rounded-sm flex items-center justify-center h-8 w-8 text-[#000000]">
                                        <AnswerIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex w-full min-w-0 flex-col">
                        <div className="flex-col gap-1 lg:gap-3">
                            <div className="flex flex-grow flex-col max-w-full">
                                <div className="flex flex-col gap-1 lg:gap-3">
                                    <div className="flex flex-grow flex-col max-w-full">
                                        <div className="min-h-[20px] text-message flex flex-col whitespace-pre-wrap break-words w-full items-end overflow-x-auto gap-2">
                                            <div className="flex w-full flex-col gap-1 first:pt-[3px]">
                                                <div className="w-full break-words">
                                                    <div
                                                        className="chat-stream stream-table text-base leading-[1.75]"
                                                        dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
                                                    ></div>
                                                    <div>
                                                        {!!parsedAnswer.citations.length && <div className="font-semibold my-1">Sources</div>}
                                                        {parsedAnswer.citations.map((x, i) => {
                                                            let title = x.replace(/\.\w+#page=/, " - Page ");
                                                            let citationTitle = x.replace(/\.\w+#page=.*$/, "").replace(/\.mp4\?url=.*$/, "");
                                                            const path = getCitationFilePath(x, appStateContext?.state.token?.accessToken);
                                                            return (
                                                                <a
                                                                    key={i}
                                                                    className="flex flex-col text-jtc-purple hover:text-jtc-blue cursor-pointer"
                                                                    onClick={() => onCitationClicked(path, citationTitle)}
                                                                >
                                                                    {`${i + 1}. ${title}`}
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(!isStreaming || !lastAnswer) && (
                                            <div className="mt-2 flex gap-3 ml-[-3px]">
                                                <div className="items-center justify-start rounded-xl p-1 flex">
                                                    <div className="flex items-center">
                                                        <span className="h-[30px] w-[30px]">
                                                            <ButtonTooltip title="Copy">
                                                                <button
                                                                    className="flex justify-center font-semibold text-jtc-purple hover:text-jtc-blue"
                                                                    onClick={() => onCopyClicked()}
                                                                >
                                                                    <span className="material-symbols-outlined text-[1.25rem]">{copyIcon}</span>
                                                                </button>
                                                            </ButtonTooltip>
                                                        </span>
                                                        {!(sanitizedThoughts === "None") && (
                                                            <span className="h-[30px] w-[30px]">
                                                                <ButtonTooltip title={showThoughts ? "Hide thought process" : "Show thought process"}>
                                                                    <button
                                                                        className={
                                                                            "flex justify-center font-semibold text-jtc-purple pb-[3px] hover:text-jtc-blue " +
                                                                            (showThoughts ? "icon-underline" : "")
                                                                        }
                                                                        onClick={() => onThoughtsClicked()}
                                                                        disabled={!thoughts}
                                                                    >
                                                                        <span className="material-symbols-outlined text-[1.25rem]">lightbulb</span>
                                                                    </button>
                                                                </ButtonTooltip>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {showThoughts && (
                                            <div className="bg-jtc-grey py-4 px-5 rounded-lg mt-4">
                                                <div className="font-semibold text-xl mb-2">Thought process</div>
                                                <div className="stream-table" dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
