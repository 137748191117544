import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";

import { Assistant } from "../../api";

interface Props {
    onAssistantSelected: () => void;
    windowWidth: number;
}

export const ModelSelector = ({ onAssistantSelected, windowWidth }: Props) => {
    const appStateContext = useContext(AppStateContext);

    const onAssistantClicked = (assist: Assistant) => {
        appStateContext?.dispatch({ type: "UPDATE_ASSISTANT", payload: assist });
        onAssistantSelected();
    };

    return (
        <Menu>
            <MenuButton className="group flex cursor-pointer items-center text-jtc-white overflow-hidden whitespace-nowrap tracking-[.15rem] hover:bg-jtc-purple px-2.5 h-[40px] rounded-lg">
                <div className="after:content-['›'] after:inline-block after:ml-2.5 after:rotate-90">{appStateContext?.state.assistant?.short_title}</div>
            </MenuButton>
            <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <MenuItems
                    anchor={windowWidth >= 976 ? "bottom start" : "bottom"}
                    className={"w-fit origin-top-right rounded-lg border bg-white p-1 text-sm text-jtc-purple focus:outline-none translate-y-1"}
                >
                    {appStateContext?.state.assistants &&
                        appStateContext?.state.assistants.map((assist, index) => (
                            <MenuItem>
                                <button
                                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:text-jtc-blue font-semibold tracking-[.15rem]"
                                    onClick={() => onAssistantClicked(assist)}
                                >
                                    {/* <span className="material-symbols-outlined text-[1.25rem]">grade</span> */}
                                    {assist.title}
                                </button>
                            </MenuItem>
                        ))}
                </MenuItems>
            </Transition>
        </Menu>
    );
};
