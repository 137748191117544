import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { ButtonTooltip } from "../ButtonTooltip";
import { ModelSelector } from "../ModelSelector";
import shield from "../../assets/shield-white.svg";

interface Props {
    onNewClick: () => void;
    newDisabled?: boolean;
    onHistoryClick: () => void;
    emptyChat: boolean;
    windowWidth: number;
}

export const ChatHeader = ({ onNewClick, newDisabled, onHistoryClick, emptyChat, windowWidth }: Props) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div
            className={
                "sticky top-0 px-4 py-3 flex items-center justify-between z-20 h-16 font-semibold " +
                (appStateContext?.state.isChatHistoryOpen ? "md:px-4" : "md:px-16")
            }
        >
            <div className="flex items-center overflow-hidden gap-0 md:w-96">
                <div className="flex items-center bg-jtc-pink opacity-90 rounded-lg h-[40px] gap-1">
                    {(!appStateContext?.state.isChatHistoryOpen || windowWidth < 976) && (
                        <ButtonTooltip title="Show history">
                            <span className="">
                                <a
                                    className="flex items-center h-[40px] rounded-lg text-jtc-white hover:bg-jtc-purple cursor-pointer px-[6.5px]"
                                    onClick={onHistoryClick}
                                >
                                    <span className="shrink-0 text-2xl material-symbols-outlined">left_panel_open</span>
                                </a>
                            </span>
                        </ButtonTooltip>
                    )}
                    {!appStateContext?.state.isChatHistoryOpen &&
                        windowWidth >= 976 &&
                        (!newDisabled ? (
                            <ButtonTooltip title="New chat">
                                <span className="">
                                    <a
                                        className={"flex items-center h-[40px] rounded-lg text-jtc-white hover:bg-jtc-purple cursor-pointer px-[6.5px]"}
                                        onClick={onNewClick}
                                    >
                                        <span className="shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                                    </a>
                                </span>
                            </ButtonTooltip>
                        ) : (
                            <span className="">
                                <a className={"flex items-center h-[40px] rounded-lg text-jtc-disabled cursor-default px-[6.5px]"} onClick={onNewClick}>
                                    <span className="shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                                </a>
                            </span>
                        ))}
                    {appStateContext?.state.assistant &&
                        windowWidth >= 976 &&
                        (appStateContext.state.urlAssistant ? (
                            <div className="group flex items-center text-jtc-white overflow-hidden whitespace-nowrap tracking-[.15rem] px-2.5 h-[40px] rounded-lg">
                                <div>{appStateContext?.state.assistant?.short_title}</div>
                            </div>
                        ) : (
                            <ModelSelector onAssistantSelected={onNewClick} windowWidth={windowWidth} />
                        ))}
                </div>
            </div>
            <div className="flex justify-center grow">
                {!emptyChat && windowWidth >= 976 && (
                    <div className="flex self-center whitespace-nowrap py-0">
                        <div className="text-sm	leading-6 font-bold">
                            <img src={shield} alt="ChatJTC Shield Logo" className="h-[45px] w-auto" />
                        </div>
                    </div>
                )}
                {windowWidth < 976 && (
                    <div className="flex items-center overflow-hidden gap-0">
                        <div className="flex items-center bg-jtc-pink opacity-90 rounded-lg h-[40px]">
                            {appStateContext?.state.assistant &&
                                (appStateContext.state.urlAssistant ? (
                                    <div className="group flex items-center text-jtc-white overflow-hidden whitespace-nowrap tracking-[.15rem] px-2.5 h-[40px] rounded-lg">
                                        <div>{appStateContext?.state.assistant?.short_title}</div>
                                    </div>
                                ) : (
                                    <ModelSelector onAssistantSelected={onNewClick} windowWidth={windowWidth} />
                                ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="flex items-center overflow-hidden gap-0 md:w-96">
                <div className={"flex items-center " + (windowWidth < 976 ? "bg-jtc-pink opacity-90 rounded-lg h-[40px]" : "")}>
                    {windowWidth < 976 &&
                        (!newDisabled ? (
                            <ButtonTooltip title="New chat">
                                <span className="">
                                    <a
                                        className={"flex items-center h-[40px] rounded-lg text-jtc-white hover:bg-jtc-purple cursor-pointer px-[6.5px]"}
                                        onClick={onNewClick}
                                    >
                                        <span className="shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                                    </a>
                                </span>
                            </ButtonTooltip>
                        ) : (
                            <span className="">
                                <a className={"flex items-center h-[40px] rounded-lg text-jtc-disabled cursor-default px-[6.5px]"} onClick={onNewClick}>
                                    <span className="shrink-0 text-2xl material-symbols-outlined pt-[3px]">add_comment</span>
                                </a>
                            </span>
                        ))}
                </div>
            </div>
        </div>
    );
};
