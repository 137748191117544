import React from "react";
import createSvgIcon from "@fluentui/react-icons-mdl2/lib/utils/createSvgIcon";

export const GPT4SparkleIcon = createSvgIcon({
    svg: ({ classes }) =>
        React.createElement(
            "svg",
            { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", className: classes.svg, focusable: "false" },
            React.createElement("path", {
                d: "M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z"
            })
        ),
    displayName: "GPT4SparkleIcon"
});

export const JTCShieldIcon = createSvgIcon({
    svg: ({ classes }) =>
        React.createElement(
            "svg",
            { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 108.36 108.36", className: classes.svg, focusable: "false" },
            React.createElement("path", { d: "M93.085,76.46 L54.175,108.36 L15.275,76.46 L15.275,0.64 L93.085,0.64 L93.085,76.46 z" })
        ),
    displayName: "JTCShieldIcon"
});
