import { useRef, useState, useEffect, useContext } from "react";
import readNDJSONStream from "ndjson-readablestream";
import { throttle } from "lodash";
import { Spinner, ISpinnerStyles } from "@fluentui/react";

import {
    chatApi,
    ChatRequest,
    generateHistory,
    HistoryRequest,
    AssistantsLoadingState,
    ChatResponse,
    Message,
    MessageContext,
    getDocumentText
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { AppStateContext } from "../../state/AppProvider";

import { MobileSidebar } from "../../components/MobileSidebar";
import { DefaultSidebar } from "../../components/DefaultSidebar";
import { ChatHeader } from "../../components/ChatHeader";
import { ButtonTooltip } from "../../components/ButtonTooltip";

import { ChatHistoryLoadingState, CosmosDBStatus } from "../../api";
import { Conversation } from "../../api";
import { LoginExpired } from "../../components/LoginExpired";

const Chat = () => {
    let userErrorMessage: string = "Something went wrong. If this issue persists, please contact helpdesk@jtcgroup.com.";

    const [iframeKey, setIframeKey] = useState(0);

    const [conversationId, setConversationId] = useState<string>();
    const lastQuestionRef = useRef<string>("");
    const lastQuestionFilesRef = useRef<string[]>([]);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const chatScrollRef = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeCitationTitle, setActiveCitationTitle] = useState<string>();
    const [showThoughts, setShowThoughts] = useState<boolean[]>([]);
    const [questionFiles, setQuestionFiles] = useState<{ files: string[] }[]>([]);
    const [isAutoScroll, setIsAutoScroll] = useState<boolean>(true);

    const [answers, setAnswers] = useState<[user: string, response: ChatResponse][]>([]);
    const [streamedAnswers, setstreamedAnswers] = useState<[user: string, response: ChatResponse][]>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const appStateContext = useContext(AppStateContext);

    const handleAsyncRequest = async (question: string, answers: [string, ChatResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatResponse = {} as ChatResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setstreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (
                    event["choices"] &&
                    event["choices"][0]["context"] &&
                    event["choices"][0]["context"]["metadata"] &&
                    event["choices"][0]["context"]["metadata"]["thoughts"]
                ) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatResponse;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };

    const client = useMsal().instance;

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;
        let newQuestionFiles: string[] = [];
        if (appStateContext?.state.files && appStateContext?.state.files.length > 0) {
            newQuestionFiles = appStateContext?.state.files
                .filter(fileItem => fileItem.new)
                .map(fileItem => {
                    return fileItem.file.name;
                });
        }
        lastQuestionFilesRef.current = newQuestionFiles;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveCitationTitle(undefined);
        setShowThoughts(currentShowThoughts => [...currentShowThoughts, false]);
        setQuestionFiles(currentQuestionFiles => [...currentQuestionFiles, { files: newQuestionFiles }]);
        appStateContext?.dispatch({ type: "TOGGLE_ANSWER_LOADING" });
        appStateContext?.dispatch({ type: "UPDATE_ALL_FILES_NEW" });

        const token = client ? await getToken(client) : undefined;

        try {
            const messages: Message[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            let metadata: Record<string, any> | undefined = undefined;
            if (appStateContext?.state.files && appStateContext?.state.files.length > 0) {
                metadata = {
                    files: appStateContext?.state.files.map(fileItem => {
                        return {
                            fileName: fileItem.file.name,
                            fileText: fileItem.text,
                            newFile: fileItem.new
                        };
                    })
                };
            }

            let userContext: MessageContext = {
                chat_status: answers.length ? answers[answers.length - 1][1].choices[0].context.chat_status : null,
                metadata: metadata
            };

            const request: ChatRequest = {
                assistant: appStateContext?.state.assistant?.assistant || "",
                messages: [...messages, { content: question, role: "user" }],
                context: userContext,
                stream: true,
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            const response = await chatApi(request, token?.accessToken);
            if (!response.body) {
                throw Error("No response body");
            }
            const parsedResponse: ChatResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
            if (parsedResponse.choices[0].message.content === "") {
                throw Error(userErrorMessage);
            }

            setAnswers((prevAnswers: [user: string, response: ChatResponse][]) => {
                const updatedAnswers: [user: string, response: ChatResponse][] = [...prevAnswers, [question, parsedResponse]];

                const messages: Message[] = updatedAnswers.flatMap(a => [
                    { content: a[0], role: "user" },
                    { content: a[1].choices[0].message.content, role: "assistant" }
                ]);

                const historyRequest: HistoryRequest = {
                    conversation_id: conversationId,
                    assistant: appStateContext?.state.assistant?.assistant || "",
                    messages: [...messages],
                    context: parsedResponse.choices[0].context,
                    user_context: userContext
                };

                generateHistory(historyRequest, token?.accessToken)
                    .then(histResponse => {
                        // Ensure the response is OK and parse it as JSON
                        if (!histResponse.ok) {
                            throw Error("Network response was not ok");
                        }
                        return histResponse.json();
                    })
                    .then(data => {
                        let resultConversation: Conversation | undefined;
                        if (!data.conversation) {
                            throw Error("Invalid response body");
                        }
                        if (!conversationId) {
                            resultConversation = {
                                id: data.conversation.id,
                                assistant: data.conversation.assistant,
                                title: data.conversation.title,
                                messages: data.messages,
                                date: data.conversation.date
                            };
                            appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: resultConversation });
                            setConversationId(data.conversation.conversation_id);
                        } else {
                            resultConversation = appStateContext?.state?.chatHistory?.find(conv => conv.id === conversationId);
                            if (!resultConversation) {
                                throw Error("Invalid conversation id");
                            }
                            resultConversation.messages.push(data.messages[0]);
                            resultConversation.messages.push(data.messages[1]);
                            resultConversation.date = data.conversation.date;
                            let updatedConversation = { ...resultConversation };
                            appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: resultConversation });
                        }
                        return resultConversation;
                    })
                    .then(resultConversation => {
                        if (resultConversation) {
                            appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: resultConversation });
                        }
                    })
                    .catch(e => {
                        appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                        appStateContext?.dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                        console.error(e);
                    });

                return updatedAnswers; // return updated state
            });
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
            appStateContext?.dispatch({ type: "TOGGLE_ANSWER_LOADING" });
        }
    };

    const clearChat = () => {
        if ((lastQuestionRef.current || appStateContext?.state.files.length) && !appStateContext?.state.answerLoading) {
            lastQuestionRef.current = "";
            lastQuestionFilesRef.current = [];
            error && setError(undefined);
            setActiveCitation(undefined);
            setActiveCitationTitle(undefined);
            setShowThoughts([]);
            setAnswers([]);
            setstreamedAnswers([]);
            setQuestionFiles([]);
            setConversationId(undefined);
            appStateContext?.dispatch({ type: "REMOVE_ALL_FILES" });
            if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.value = "";
            }
            appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
            if (windowWidth < 768 && appStateContext?.state.isChatHistoryOpen) {
                appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
            }
        }
    };

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    useEffect(() => {
        if (isAutoScroll) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" });
        }
    }, [streamedAnswers]);

    const scrollToEnd = () => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleScroll = () => {
        const chatContainer = chatScrollRef.current;
        if (chatContainer) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainer;
            const isScrolledToBottom = Math.ceil(scrollTop + clientHeight) + 1 >= scrollHeight;
            setIsAutoScroll(isScrolledToBottom);
        }
    };

    useEffect(() => {
        const chatContainer = chatScrollRef.current;
        if (chatContainer) {
            chatContainer.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, [lastQuestionRef.current]);

    const onShowCitation = (citation: string, title: string, index: number) => {
        if (windowWidth < 1280) {
            window.open(citation, "_blank");
        } else {
            setActiveCitation(citation);
            setActiveCitationTitle(title);
            setIframeKey(prevKey => prevKey + 1);
        }
    };

    const onCloseCitation = () => {
        setActiveCitation(undefined);
        setActiveCitationTitle(undefined);
    };

    const onOpenCitation = () => {
        window.open(activeCitation, "_blank");
    };

    const onThoughtProcessClicked = (index: number) => {
        if (appStateContext?.state.currentChat && showThoughts.length === 0) {
            const updatedShowThoughts: boolean[] = [];
            const messages = appStateContext.state.currentChat.messages;

            for (let i = 0; i < messages.length - 1; i++) {
                if (messages[i].message.role === "user" && messages[i + 1].message.role === "assistant") {
                    updatedShowThoughts.push(false);
                }
            }
            setShowThoughts(updatedShowThoughts);
        }

        setShowThoughts(currentShowThoughts => {
            const updatedShowThoughts = [...currentShowThoughts];
            updatedShowThoughts[index] = !updatedShowThoughts[index];
            return updatedShowThoughts;
        });
    };

    const handleHistoryClick = () => {
        appStateContext?.state.isChatHistoryOpen
            ? document.documentElement.style.setProperty("--ch", `${0}px`)
            : document.documentElement.style.setProperty("--ch", `${300}px`);
        appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const vh = window.innerHeight;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--ch", `${0}px`);
    }, []);

    useEffect(() => {
        // The function won't be called more often than every 200ms
        const handleResize = throttle(() => {
            const vh = window.innerHeight;
            const vw = window.innerWidth;
            setWindowHeight(vh);
            setWindowWidth(vw);
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        }, 200);

        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (appStateContext?.state.currentChat && appStateContext?.state.currentChat.id !== conversationId) {
            if (conversationId) {
                appStateContext?.dispatch({ type: "REMOVE_ALL_FILES" });
                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
            error && setError(undefined);
            setActiveCitation(undefined);

            const updatedShowThoughts: boolean[] = [];
            const transformedAnswers: [string, ChatResponse][] = [];
            const messages = appStateContext.state.currentChat.messages;
            const files: { files: string[] }[] = [];

            for (let i = 0; i < messages.length - 1; i++) {
                if (messages[i].message.role === "user" && messages[i + 1].message.role === "assistant") {
                    transformedAnswers.push([
                        messages[i].message.content as string,
                        {
                            choices: [
                                {
                                    index: i,
                                    message: messages[i + 1].message,
                                    context: messages[i + 1].context,
                                    session_state: null
                                }
                            ]
                        } as ChatResponse
                    ]);
                    let allFiles = messages[i].context.metadata?.files;
                    if (allFiles) {
                        let newFiles = allFiles.filter((fileItem: { fileName: string; fileText: string; newFile: string }) => fileItem.newFile);
                        if (newFiles) {
                            files.push({ files: newFiles.map((fileItem: { fileName: string; fileText: string; newFile: string }) => fileItem.fileName) });
                            newFiles.map(
                                (fileItem: { fileName: string; fileText: string; newFile: string }) =>
                                    appStateContext?.dispatch({
                                        type: "ADD_CHAT_HISTORY_FILE",
                                        payload: { name: fileItem.fileName, text: fileItem.fileText }
                                    })
                            );
                        } else {
                            files.push({ files: [] });
                        }
                    } else {
                        files.push({ files: [] });
                    }
                    updatedShowThoughts.push(false);
                }
            }
            if (messages[messages.length - 1].message.role === "user") {
                // Final assistant message is missing
                setError(userErrorMessage);
            }

            setShowThoughts(updatedShowThoughts);
            setAnswers(transformedAnswers);
            setstreamedAnswers(transformedAnswers);
            setQuestionFiles(files);

            setConversationId(appStateContext.state.currentChat.id);

            if (appStateContext.state.assistants) {
                const currentAssistant = appStateContext.state.assistants?.find(assist => assist.assistant === appStateContext.state.currentChat?.assistant);
                appStateContext?.dispatch({ type: "UPDATE_ASSISTANT", payload: currentAssistant || null });
            }

            for (let i = messages.length - 1; i >= 0; i--) {
                if (messages[i].message.role === "user") {
                    lastQuestionRef.current = messages[i].message.content as string;
                    break;
                }
            }
        } else if (appStateContext?.state.currentChat) {
            const transformedAnswers: [string, ChatResponse][] = [];
            const messages = appStateContext.state.currentChat.messages;
            for (let i = 0; i < messages.length - 1; i++) {
                if (messages[i].message.role === "user" && messages[i + 1].message.role === "assistant") {
                    transformedAnswers.push([
                        messages[i].message.content as string,
                        {
                            choices: [
                                {
                                    index: i,
                                    message: messages[i + 1].message,
                                    context: messages[i + 1].context,
                                    session_state: null
                                }
                            ]
                        } as ChatResponse
                    ]);
                }
            }
            setAnswers(transformedAnswers);
            setstreamedAnswers(transformedAnswers);
        } else {
            clearChat;
        }
    }, [appStateContext?.state.currentChat]);

    function copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    console.log("Text copied to clipboard");
                })
                .catch(err => {
                    console.error("Failed to copy text using Clipboard API: ", err);
                    // Try the fallback method
                    fallbackCopyTextToClipboard(text);
                });
        } else {
            fallbackCopyTextToClipboard(text);
        }
    }

    function fallbackCopyTextToClipboard(text: string) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            // Using the Clipboard API again for the fallback method
            navigator.clipboard.writeText(text);
            console.log("Text copied to clipboard using fallback");
        } catch (err) {
            console.error("Fallback failed too: ", err);
        }
        document.body.removeChild(textArea);
    }

    const spinnerStyles: ISpinnerStyles = {
        circle: {
            borderColor: "#019dcd #019dcd #c2f1ff00 #c2f1ff00",
            height: "16px",
            width: "16px",
            border: "2px solid"
        }
    };

    const MAX_FILES = 2;

    const uploadFile = () => {
        const input = fileInputRef.current;
        if (!input || !input.files?.length || !appStateContext?.state.files) {
            return;
        } else {
            const filesArray = Array.from(input.files);
            if (appStateContext?.state.files.filter(fileItem => fileItem.new).length + filesArray.length > MAX_FILES) {
                alert(`You can only upload a maximum of ${MAX_FILES} files.`);
                return;
            }
            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            const allAreAllowedTypes = filesArray.every(file => allowedTypes.includes(file.type));
            if (!allAreAllowedTypes) {
                alert("Only PDF or Word documents are allowed.");
                return;
            }

            filesArray.map((file, index) => {
                if (file.size > 10485760) {
                    alert(`File ${file.name} exceeds the maximum size of 10MB.`);
                    return;
                }
                appStateContext?.dispatch({ type: "SET_ANSWER_LOADING", payload: true });
                appStateContext?.dispatch({ type: "ADD_FILE", payload: file.name });
                const formData = new FormData();
                if (input.files) {
                    formData.append("file", input.files[index]);
                }
                getDocumentText(formData, appStateContext?.state.token?.accessToken)
                    .then(response => response.json())
                    .then(data => {
                        appStateContext?.dispatch({
                            type: "UPDATE_FILE_TEXT",
                            payload: { name: file.name, text: data.text }
                        });
                        appStateContext?.dispatch({
                            type: "UPDATE_FILE_STATUS",
                            payload: { name: file.name, status: "Success" }
                        });
                        appStateContext?.dispatch({ type: "SET_ANSWER_LOADING", payload: false });
                    })
                    .catch(error => {
                        console.error(error);
                        appStateContext?.dispatch({
                            type: "UPDATE_FILE_STATUS",
                            payload: { name: file.name, status: "Error" }
                        });
                        appStateContext?.dispatch({ type: "SET_ANSWER_LOADING", payload: false });
                    });
            });
        }
    };

    return (
        <div className="relative z-0 flex h-full w-full overflow-hidden">
            {windowWidth >= 976 && (
                <DefaultSidebar
                    onNewClick={clearChat}
                    newDisabled={(!lastQuestionRef.current && !appStateContext?.state.files.length) || appStateContext?.state.answerLoading}
                    onHistoryClick={handleHistoryClick}
                />
            )}
            {appStateContext?.state.loginExpired && <LoginExpired open={true} />}
            <div
                className={
                    "relative flex h-full max-w-full flex-1 flex-col overflow-hidden login-background transition-all duration-1000" +
                    (!lastQuestionRef.current ? " " : " login-background-chat")
                }
            >
                <ChatHeader
                    onNewClick={clearChat}
                    newDisabled={(!lastQuestionRef.current && !appStateContext?.state.files.length) || appStateContext?.state.answerLoading}
                    onHistoryClick={handleHistoryClick}
                    emptyChat={!lastQuestionRef.current}
                    windowWidth={windowWidth}
                />
                <main
                    className={
                        "relative h-full w-full flex-1 overflow-auto transition-width " +
                        (activeCitation ? "xl:flex " : "") +
                        (activeCitation && !appStateContext?.state.isChatHistoryOpen ? "xl:pl-12" : "")
                    }
                >
                    <div role="presentation" tabIndex={0} className={"flex h-full flex-col focus-visible:outline-0 " + (activeCitation ? "xl:w-1/2" : "")}>
                        <div className="flex-1 overflow-hidden">
                            {appStateContext?.state.assistant &&
                                (!lastQuestionRef.current ? (
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-5 px-8 rounded-none align-center z-[1]">
                                        <div className="mb-5 mt-2 min-w-[15rem] max-w-lg">
                                            <div className="mx-4 mt-6 flex max-w-3xl flex-wrap items-stretch justify-center gap-4"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="relative h-full">
                                        <div className="h-full overflow-y-auto w-full" ref={chatScrollRef}>
                                            <div className="p-0">
                                                <div className="flex flex-col pb-[18px]">
                                                    {isStreaming &&
                                                        streamedAnswers.map((streamedAnswer, index) => (
                                                            <div key={index} className={"" + (index === 0 ? "" : "")}>
                                                                <UserChatMessage message={streamedAnswer[0]} files={questionFiles[index].files} />
                                                                <Answer
                                                                    isStreaming={true}
                                                                    key={index}
                                                                    answer={streamedAnswer[1]}
                                                                    onCitationClicked={(filePath, citationTitle) =>
                                                                        onShowCitation(filePath, citationTitle, index)
                                                                    }
                                                                    onThoughtProcessClicked={() => onThoughtProcessClicked(index)}
                                                                    onCopyAnswerClicked={() => copyToClipboard(streamedAnswer[1].choices[0].message.content)}
                                                                    showThoughts={showThoughts[index]}
                                                                    lastAnswer={index === answers.length}
                                                                    windowWidth={windowWidth}
                                                                />
                                                            </div>
                                                        ))}
                                                    {!isStreaming &&
                                                        answers.map((answer, index) => (
                                                            <div key={index} className={"" + (index === 0 ? "" : "")}>
                                                                <UserChatMessage message={answer[0]} files={questionFiles[index].files} />
                                                                <Answer
                                                                    isStreaming={false}
                                                                    key={index}
                                                                    answer={answer[1]}
                                                                    onCitationClicked={(filePath, citationTitle) =>
                                                                        onShowCitation(filePath, citationTitle, index)
                                                                    }
                                                                    onThoughtProcessClicked={() => onThoughtProcessClicked(index)}
                                                                    onCopyAnswerClicked={() => copyToClipboard(answer[1].choices[0].message.content)}
                                                                    showThoughts={showThoughts[index]}
                                                                    lastAnswer={index === answers.length}
                                                                    windowWidth={windowWidth}
                                                                />
                                                            </div>
                                                        ))}
                                                    {isLoading && (
                                                        <div className={"" + (answers.length === 0 ? "" : "")}>
                                                            <UserChatMessage message={lastQuestionRef.current} files={lastQuestionFilesRef.current} />
                                                            <AnswerLoading />
                                                        </div>
                                                    )}
                                                    {error ? (
                                                        <div className={"" + (answers.length === 0 ? "" : "")}>
                                                            <UserChatMessage message={lastQuestionRef.current} files={lastQuestionFilesRef.current} />
                                                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                        </div>
                                                    ) : null}
                                                    <div ref={chatMessageStreamEnd} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {appStateContext?.state.assistant && (
                            <div className="w-full pt-0">
                                <QuestionInput
                                    clearOnSend
                                    disabled={appStateContext.state.answerLoading || appStateContext.state.files.some(file => file.status === "Loading")}
                                    onSend={question => makeApiRequest(question)}
                                    chatEmpty={!lastQuestionRef.current}
                                    windowWidth={windowWidth}
                                    fileInputRef={fileInputRef}
                                    uploadFile={uploadFile}
                                    showToEnd={!isAutoScroll && lastQuestionRef.current !== ""}
                                    toEnd={scrollToEnd}
                                />
                            </div>
                        )}
                        {appStateContext?.state.assistantsLoadingState === AssistantsLoadingState.Loading && (
                            <div className="relative h-full">
                                <div className="flex h-full flex-col items-center justify-center">
                                    <a className="pt-24 gap-1 w-fit font-bold flex text-center items-center text-lg whitespace-nowrap">
                                        <span className="flex justify-center items-center h-[26.67px] w-[26.67px]">
                                            <Spinner style={{ display: "block", verticalAlign: "middle" }} styles={spinnerStyles} />
                                        </span>
                                        <span className="text-[0.875rem] text-jtc-white uppercase tracking-[.15rem]">
                                            <span className="">Loading assistants</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                    {activeCitation && (
                        <div className="flex-1 overflow-y-auto ml-6 mb-[36px] hidden w-1/2 xl:flex">
                            <div className="w-full pb-4 px-4 justify-center md:gap-6 m-auto lg:mr-16 bg-jtc-grey rounded-lg">
                                <div className="relative flex w-full flex-col">
                                    <div className="flex justify-between height-[40px] my-2">
                                        <div className="flex grow justify-start max-w-[calc(100%-4rem)] items-center">
                                            <div className="leading-7 font-semibold text-jtc-purple text-[0.875rem] tracking-[.15rem] text-ellipsis overflow-x-hidden whitespace-nowrap">
                                                {activeCitationTitle}
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <ButtonTooltip title="Hide source">
                                                <span className="">
                                                    <a
                                                        className={
                                                            "flex items-center h-[40px] rounded-lg text-jtc-purple hover:text-jtc-blue cursor-pointer px-[6.5px]"
                                                        }
                                                        onClick={onCloseCitation}
                                                    >
                                                        <span className="shrink-0 text-2xl material-symbols-outlined pt-[3px]">visibility_off</span>
                                                    </a>
                                                </span>
                                            </ButtonTooltip>
                                        </div>
                                    </div>
                                    <iframe key={iframeKey} title="Citation" src={activeCitation} width="100%" height={windowHeight - 245.25 + "px"} />
                                </div>
                            </div>
                        </div>
                    )}
                </main>
            </div>
            {windowWidth < 976 && (
                <MobileSidebar
                    onNewClick={clearChat}
                    newDisabled={(!lastQuestionRef.current && !appStateContext?.state.files.length) || appStateContext?.state.answerLoading}
                    onHistoryClick={handleHistoryClick}
                />
            )}
        </div>
    );
};

export default Chat;
