import { Spinner, Stack, StackItem, Text, ISpinnerStyles } from "@fluentui/react";

import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import ChatHistoryList from "./ChatHistoryList";
import { ChatHistoryLoadingState } from "../../api";

export function ChatHistoryPanel() {
    const appStateContext = useContext(AppStateContext);

    const spinnerStyles: ISpinnerStyles = {
        circle: {
            borderColor: "#019dcd #019dcd #c2f1ff00 #c2f1ff00",
            height: "16px",
            width: "16px",
            border: "2px solid"
        }
    };

    return (
        <div>
            {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Success && appStateContext?.state.isCosmosDBAvailable.cosmosDB && (
                <ChatHistoryList />
            )}
            {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && appStateContext?.state.isCosmosDBAvailable && (
                <>
                    <Stack>
                        <Stack horizontalAlign="center" verticalAlign="center" style={{ width: "100%", marginTop: 10 }}>
                            <StackItem>
                                <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 16 }}>
                                    {appStateContext?.state.isCosmosDBAvailable?.status && <span>{appStateContext?.state.isCosmosDBAvailable?.status}</span>}
                                    {!appStateContext?.state.isCosmosDBAvailable?.status && <span>Error loading chat history</span>}
                                </Text>
                            </StackItem>
                            <StackItem>
                                <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}>
                                    <span>Chat history can't be saved at this time</span>
                                </Text>
                            </StackItem>
                        </Stack>
                    </Stack>
                </>
            )}
            {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading && (
                <div className="grow flex flex-col justify-center items-center py-0 w-full whitespace-nowrap text-ellipsis">
                    <div className="items-center flex py-4 px-0 border-none justify-start w-auto h-[60px]">
                        <a className="gap-1 w-fit font-bold flex text-center items-center text-lg whitespace-nowrap">
                            <span className="flex justify-center items-center h-[26.67px] w-[26.67px]">
                                <Spinner style={{ display: "block", verticalAlign: "middle" }} styles={spinnerStyles} />
                            </span>
                            <span className="text-[0.875rem] uppercase text-jtc-pink tracking-[.15rem]">
                                <span className="">Loading</span>
                            </span>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}
