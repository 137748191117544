import { Fragment, useRef } from "react";
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from "@headlessui/react";
import { useMsal } from "@azure/msal-react";

interface Props {
    open: boolean;
}

export const LoginExpired = ({ open }: Props) => {
    const cancelButtonRef = useRef(null);

    const { instance } = useMsal();
    const handleLogOutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: "/", // redirects the top level app after logout
                account: instance.getActiveAccount()
            })
            .catch(error => console.log(error));
    };

    return (
        <Transition show={open} as={Fragment}>
            <Dialog className="relative z-[100]" initialFocus={cancelButtonRef} onClose={() => undefined}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-xl rounded-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="ml-0 sm:mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-jtc-pink/20 sm:mx-0 sm:h-10 sm:w-10">
                                            <span className="material-symbols-outlined text-[1.5rem] text-jtc-purple">warning</span>
                                        </div>
                                        <div className="mt-3 sm:ml-4 sm:mt-0 text-left">
                                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Your session has expired
                                            </DialogTitle>
                                            <div className="chat-stream mt-2">
                                                <p className="text-sm text-gray-500">Please log out and then log back in to continue using ChatJTC.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 pb-6 flex flex-row sm:flex-row-reverse sm:px-6 gap-4">
                                    <a
                                        className="bg-jtc-pink hover:bg-jtc-purple flex items-center justify-center h-[40px] pl-[17px] pr-[15px] rounded-button text-jtc-white cursor-pointer"
                                        onClick={handleLogOutPopup}
                                    >
                                        <div className="font-black tracking-[0.15rem] text-[.75rem] uppercase">Log out</div>
                                    </a>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
