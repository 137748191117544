export const enum Assistants {
    GPT4 = "gpt4",
    HRAssistant = "hr"
}

export type Assistant = {
    assistant: string;
    title: string;
    examples: string[];
    type: string;
    short_title: string;
    url: string;
    attachments: boolean;
};

export type Message = {
    role: string;
    content: string;
};

export type MessageContext = {
    chat_status: Record<string, any> | null;
    metadata?: Record<string, any>;
    followup_questions?: string[] | null;
};

export type Choice = {
    index: number;
    message: Message;
    context: MessageContext;
    session_state: any;
};

export type ChatResponse = {
    choices: Choice[];
};

export type ChatResponseOrError = {
    choices?: Choice[];
    error?: string;
};

export type ChatRequest = {
    assistant: string;
    messages: Message[];
    context: MessageContext;
    stream?: boolean;
    session_state: any;
};

export type ChatMessage = {
    id: string | null;
    message: Message;
    context: MessageContext;
    date: string;
};

export type Conversation = {
    id: string;
    assistant: string;
    title: string;
    messages: ChatMessage[];
    date: string;
};

export type HistoryRequest = {
    conversation_id?: string;
    assistant: string;
    messages: Message[];
    context: MessageContext;
    user_context?: MessageContext;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    Working = "CosmosDB is configured and working"
}

export type CosmosDBHealth = {
    cosmosDB: boolean;
    status: string;
};

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export enum AssistantsLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}
