import * as React from "react";

import { AppStateContext } from "../../state/AppProvider";
import { GroupedChatHistory } from "./ChatHistoryList";

import { Conversation } from "../../api/models";
import { useEffect, useState } from "react";
import { throttle } from "lodash";

interface ChatHistoryListItemGroupsProps {
    groupedChatHistory: GroupedChatHistory[];
}

const formatMonth = (month: string) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [monthName, yearString] = month.split(" ");
    const year = parseInt(yearString);

    if (year === currentYear) {
        return monthName;
    } else {
        return month;
    }
};

export const ChatHistoryListItemGroups: React.FC<ChatHistoryListItemGroupsProps> = ({ groupedChatHistory }) => {
    const appStateContext = React.useContext(AppStateContext);
    const [, setSelectedItem] = React.useState<Conversation | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // The function won't be called more often than every 200ms
        const handleResize = throttle(() => {
            setWindowWidth(window.innerWidth);
        }, 200);

        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSelectHistory = (item?: Conversation) => {
        if (item) {
            setSelectedItem(item);
        }
    };

    const handleSelectItem = (item: Conversation) => {
        if (!appStateContext?.state.answerLoading) {
            handleSelectHistory(item);
            appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: item });
            if (windowWidth < 639) {
                appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
            }
        }
    };

    return (
        <div className="">
            {groupedChatHistory.map(
                group =>
                    group.entries.length > 0 && (
                        <div className="relative mt-5 first:mt-0 last:mb-2" aria-label={`chat history group: ${group.month}`}>
                            <div className="sticky top-0 z-20 bg-jtc-grey">
                                <span className="flex h-9 items-center">
                                    <h3 className="pb-2 pt-3 px-2 text-xs font-semibold text-ellipsis overflow-hidden break-all whitespace-nowrap text-jtc-purple">
                                        {formatMonth(group.month)}
                                    </h3>
                                </span>
                            </div>
                            <ol>
                                {group.entries.map(entry => (
                                    <li className="relative z-[15]">
                                        <div className="relative rounded-lg">
                                            <a
                                                onClick={appStateContext?.state.answerLoading ? undefined : () => handleSelectItem(entry)}
                                                className={
                                                    "flex items-center gap-2 p-2 rounded-lg hover:bg-jtc-white" +
                                                    (entry.id === appStateContext?.state.currentChat?.id ? " bg-jtc-white" : " ") +
                                                    (appStateContext?.state.answerLoading ? " cursor-wait" : " cursor-pointer")
                                                }
                                            >
                                                <div className="relative grow truncate">{entry.title}</div>
                                            </a>
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    )
            )}
        </div>
    );
};
