import { useMsal } from "@azure/msal-react";

export const UserButton = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const handleLogOutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: "/", // redirects the top level app after logout
                account: instance.getActiveAccount()
            })
            .catch(error => console.log(error));
    };
    const userText = `${activeAccount?.name}`;

    return (
        <div className="flyout-container inline-block relative">
            <div className="flyout-menu hidden absolute bottom-full left-[-.5rem] z-[100] md:w-[264px] w-[284.2px] transition-all ease-linear duration-300 bg-jtc-white shadow-lg-up">
                <div className="py-2 px-5">
                    <div className="flex flex-col">
                        <div className="py-2 font-normal leading-7">
                            <a
                                className="gap-1 w-fit ease-linear transition-transform duration-300 flex cursor-pointer text-center items-center text-lg whitespace-nowrap"
                                onClick={handleLogOutPopup}
                            >
                                <span className="text-[0.875rem] text-jtc-purple hover:text-jtc-blue">
                                    <span className="pb-[3px]">Log out</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flyout-button flex justify-start cursor-pointer py-2 w-auto whitespace-nowrap items-center transition-all ease-linear duration-150 text-jtc-pink">
                <a className="text-sm leading-6 uppercase tracking-[.15rem] font-bold after:content-['‹'] after:inline-block after:ml-2.5 after:rotate-90 ">
                    {userText}
                </a>
            </div>
        </div>
    );
};
