import React, { createContext, useReducer, ReactNode, useEffect } from "react";
import { appStateReducer } from "./AppReducer";
import { historyList, historyEnsure, assistantsList, assistantsEnsure, historyCount } from "../api";
import { Conversation, Assistant, ChatHistoryLoadingState, CosmosDBHealth, CosmosDBStatus, AssistantsLoadingState } from "../api";
import { useParams } from "react-router-dom";
import { AuthenticationResult } from "@azure/msal-browser";

import { getToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

export interface AppState {
    isChatHistoryOpen: boolean;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
    assistant: Assistant | null;
    assistants: Assistant[] | null;
    assistantsLoadingState: AssistantsLoadingState;
    previousChat: Conversation | null;
    answerLoading: boolean;
    selectedAssistant: string;
    checkedAssistant: string;
    historyCount: number;
    historyCurrentCount: number;
    chatHistoryMoreLoadingState: ChatHistoryLoadingState;
    loginExpired: boolean;
    urlAssistant: string | null;
    files: { file: { name: string }; text: string; status: string; new: boolean }[];
    token: AuthenticationResult | null;
}

export type Action =
    | { type: "TOGGLE_CHAT_HISTORY" }
    | { type: "SET_COSMOSDB_STATUS"; payload: CosmosDBHealth }
    | { type: "UPDATE_CHAT_HISTORY_LOADING_STATE"; payload: ChatHistoryLoadingState }
    | { type: "UPDATE_CURRENT_CHAT"; payload: Conversation | null }
    | { type: "UPDATE_FILTERED_CHAT_HISTORY"; payload: Conversation[] | null }
    | { type: "UPDATE_CHAT_HISTORY"; payload: Conversation } // API Call
    | { type: "UPDATE_CHAT_TITLE"; payload: Conversation } // API Call
    | { type: "DELETE_CHAT_ENTRY"; payload: string } // API Call
    | { type: "DELETE_CHAT_HISTORY" } // API Call
    | { type: "DELETE_CURRENT_CHAT_MESSAGES"; payload: string } // API Call
    | { type: "FETCH_CHAT_HISTORY"; payload: Conversation[] | null } // API Call
    | { type: "UPDATE_ASSISTANT"; payload: Assistant | null }
    | { type: "UPDATE_ASSISTANT_BY_TYPE"; payload: string }
    | { type: "FETCH_ASSISTANTS"; payload: Assistant[] | null } // API Call
    | { type: "UPDATE_ASSISTANTS_LOADING_STATE"; payload: AssistantsLoadingState }
    | { type: "TOGGLE_ANSWER_LOADING" }
    | { type: "SET_ANSWER_LOADING"; payload: boolean }
    | { type: "UPDATE_SELECTED"; payload: string }
    | { type: "UPDATE_CHECKED"; payload: string }
    | { type: "SET_HISTORY_COUNT"; payload: number }
    | { type: "SET_HISTORY_CURRENT_COUNT"; payload: number }
    | { type: "FETCH_MORE_CHAT_HISTORY"; payload: Conversation[] | null } // API Call
    | { type: "UPDATE_CHAT_HISTORY_MORE_LOADING_STATE"; payload: ChatHistoryLoadingState }
    | { type: "SET_LOGIN_EXPIRED" }
    | { type: "SET_URL_ASSISTANT"; payload: string }
    | { type: "ADD_FILE"; payload: string }
    | { type: "ADD_CHAT_HISTORY_FILE"; payload: { name: string; text: string } }
    | { type: "UPDATE_FILE_TEXT"; payload: { name: string; text: string } }
    | { type: "UPDATE_FILE_STATUS"; payload: { name: string; status: string } }
    | { type: "REMOVE_FILE"; payload: number }
    | { type: "REMOVE_ALL_FILES" }
    | { type: "UPDATE_ALL_FILES_NEW" }
    | { type: "UPDATE_TOKEN"; payload: AuthenticationResult };

const initialState: AppState = {
    isChatHistoryOpen: false,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured
    },
    assistant: null,
    assistants: null,
    assistantsLoadingState: AssistantsLoadingState.Loading,
    previousChat: null,
    answerLoading: false,
    selectedAssistant: "GPT-4",
    checkedAssistant: "HR Assistant",
    historyCount: 0,
    historyCurrentCount: 0,
    chatHistoryMoreLoadingState: ChatHistoryLoadingState.Success,
    loginExpired: false,
    urlAssistant: null,
    files: [],
    token: null
};

export const AppStateContext = createContext<
    | {
          state: AppState;
          dispatch: React.Dispatch<Action>;
      }
    | undefined
>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);
    const client = useMsal().instance;
    const { assistantId } = useParams();

    useEffect(() => {
        const ensureToken = async () => {
            try {
                const response = await getToken(client);
                if (response) {
                    dispatch({ type: "UPDATE_TOKEN", payload: response });
                } else {
                    console.error("User login expired.");
                    dispatch({ type: "SET_LOGIN_EXPIRED" });
                }
            } catch (error) {
                console.error("An error occurred while fetching the token:", error);
                dispatch({ type: "SET_LOGIN_EXPIRED" });
            }
        };

        ensureToken();
    }, [client, dispatch]);

    useEffect(() => {
        const fetchChatHistory = async (): Promise<Conversation[] | null> => {
            const token = await getToken(client).then(response => {
                if (response) {
                    return response;
                }
                console.error("There was an issue fetching your data.");
                dispatch({ type: "SET_LOGIN_EXPIRED" });
                return null;
            });
            const count = await (assistantId ? historyCount(token?.accessToken, assistantId) : historyCount(token?.accessToken)).then(response => {
                if (response) {
                    dispatch({ type: "SET_HISTORY_COUNT", payload: response });
                } else {
                    dispatch({ type: "SET_HISTORY_COUNT", payload: 0 });
                }
                return response;
            });
            const result = await (assistantId ? historyList(token?.accessToken, undefined, assistantId) : historyList(token?.accessToken))
                .then(response => {
                    if (response) {
                        dispatch({ type: "FETCH_CHAT_HISTORY", payload: response });
                        dispatch({ type: "SET_HISTORY_CURRENT_COUNT", payload: response.length });
                    } else {
                        dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
                    }
                    return response;
                })
                .catch(err => {
                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
                    console.error("There was an issue fetching your data.");
                    return null;
                });
            return result;
        };

        const getHistoryEnsure = async () => {
            const token = await getToken(client);
            dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Loading });
            historyEnsure(token?.accessToken)
                .then(response => {
                    if (response?.cosmosDB) {
                        fetchChatHistory()
                            .then(res => {
                                if (res) {
                                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Success });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                                } else {
                                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                                }
                            })
                            .catch(err => {
                                dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                                dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                            });
                    } else {
                        dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                        dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                    }
                })
                .catch(err => {
                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
                });
        };
        getHistoryEnsure();
    }, []);

    useEffect(() => {
        const fetchAssistants = async (): Promise<Assistant[] | null> => {
            const token = await getToken(client).then(response => {
                if (response) {
                    return response;
                }
                console.error("There was an issue fetching your data.");
                dispatch({ type: "SET_LOGIN_EXPIRED" });
                return null;
            });
            const result = await assistantsList(token?.accessToken)
                .then(response => {
                    if (response) {
                        dispatch({ type: "FETCH_ASSISTANTS", payload: response });
                        if (assistantId) {
                            const assistant = response.find(assist => assist.url === assistantId);
                            if (assistant) {
                                dispatch({ type: "UPDATE_ASSISTANT", payload: assistant });
                                dispatch({ type: "SET_URL_ASSISTANT", payload: assistant.assistant });
                            } else {
                                dispatch({ type: "FETCH_ASSISTANTS", payload: null });
                                console.error("There was an issue fetching your assistants.");
                                return null;
                            }
                        } else {
                            dispatch({ type: "UPDATE_ASSISTANT", payload: response.filter(assistant => assistant.type === "GPT")[0] });
                        }
                    } else {
                        dispatch({ type: "FETCH_ASSISTANTS", payload: null });
                    }
                    return response;
                })
                .catch(err => {
                    dispatch({ type: "FETCH_ASSISTANTS", payload: null });
                    console.error("There was an issue fetching your assistants.");
                    return null;
                });
            return result;
        };

        const getAssistantsEnsure = async () => {
            const token = await getToken(client);
            dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Loading });
            assistantsEnsure(token?.accessToken)
                .then(response => {
                    if (response?.cosmosDB) {
                        fetchAssistants()
                            .then(res => {
                                if (res) {
                                    dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Success });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                                } else {
                                    dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Fail });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                                }
                            })
                            .catch(err => {
                                dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Fail });
                                dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                            });
                    } else {
                        dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Fail });
                        dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                    }
                })
                .catch(err => {
                    dispatch({ type: "UPDATE_ASSISTANTS_LOADING_STATE", payload: AssistantsLoadingState.Fail });
                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
                });
        };
        getAssistantsEnsure();
    }, []);

    return <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>;
};
