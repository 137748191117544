import { Stack, StackItem, Text } from "@fluentui/react";
import React, { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { ChatHistoryListItemGroups } from "./ChatHistoryListItem";
import { Conversation } from "../../api/models";
import { historyList, ChatHistoryLoadingState } from "../../api";
import { Spinner, ISpinnerStyles } from "@fluentui/react";

import { getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

interface ChatHistoryListProps {}

export interface GroupedChatHistory {
    month: string;
    entries: Conversation[];
}

const groupByTime = (entries: Conversation[]) => {
    const groups: GroupedChatHistory[] = [
        { month: "Today", entries: [] },
        { month: "Yesterday", entries: [] },
        { month: "Last 7 Days", entries: [] },
        { month: "Last 30 Days", entries: [] }
    ];

    const currentDate = new Date();
    currentDate.setHours(24, 0, 0, 0); // reset time to start of day

    entries.forEach(entry => {
        const date = new Date(entry.date);
        const daysDifference = (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

        if (daysDifference < 1) {
            groups[0].entries.push(entry);
        } else if (daysDifference < 2) {
            groups[1].entries.push(entry);
        } else if (daysDifference <= 7) {
            groups[2].entries.push(entry);
        } else if (daysDifference <= 30) {
            groups[3].entries.push(entry);
        } else {
            const monthYear = date.toLocaleString("default", { month: "long", year: "numeric" });
            const existingGroup = groups.find(group => group.month === monthYear);
            if (existingGroup) {
                existingGroup.entries.push(entry);
            } else {
                groups.push({ month: monthYear, entries: [entry] });
            }
        }
    });

    // Filter out empty groups
    return groups
        .filter(group => group.entries.length > 0)
        .map(group => {
            // Sort the entries in each group by date descending
            group.entries.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB.getTime() - dateA.getTime();
            });
            return group;
        });
};

const ChatHistoryList: React.FC<ChatHistoryListProps> = () => {
    const client = useMsal().instance;

    const appStateContext = useContext(AppStateContext);
    const chatHistory = appStateContext?.state.chatHistory;

    React.useEffect(() => {}, [appStateContext?.state.chatHistory]);

    const fetchMoreChatHistory = async (): Promise<Conversation[] | null> => {
        appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY_MORE_LOADING_STATE", payload: ChatHistoryLoadingState.Loading });

        const token = client ? await getToken(client) : undefined;
        const result = await (appStateContext?.state.urlAssistant
            ? historyList(token?.accessToken, appStateContext?.state.historyCurrentCount, appStateContext?.state.urlAssistant)
            : historyList(token?.accessToken, appStateContext?.state.historyCurrentCount)
        )
            .then(response => {
                if (response) {
                    appStateContext?.dispatch({ type: "FETCH_MORE_CHAT_HISTORY", payload: response });
                    appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY_MORE_LOADING_STATE", payload: ChatHistoryLoadingState.Success });
                } else {
                    appStateContext?.dispatch({ type: "FETCH_MORE_CHAT_HISTORY", payload: null });
                    appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY_MORE_LOADING_STATE", payload: ChatHistoryLoadingState.Success });
                }
                return response;
            })
            .catch(err => {
                appStateContext?.dispatch({ type: "FETCH_MORE_CHAT_HISTORY", payload: null });
                console.error("There was an issue fetching your data.");
                return null;
            });
        return result;
    };

    let groupedChatHistory;
    if (chatHistory && chatHistory.length > 0) {
        groupedChatHistory = groupByTime(chatHistory);
    } else {
        return (
            <Stack horizontal horizontalAlign="center" verticalAlign="center" style={{ width: "100%", marginTop: 10 }}>
                <StackItem>
                    <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}>
                        <span>No chat history.</span>
                    </Text>
                </StackItem>
            </Stack>
        );
    }

    const spinnerStyles: ISpinnerStyles = {
        circle: {
            borderColor: "#019dcd #019dcd #c2f1ff00 #c2f1ff00",
            height: "16px",
            width: "16px",
            border: "2px solid"
        }
    };

    return (
        <>
            <ChatHistoryListItemGroups groupedChatHistory={groupedChatHistory} />
            {appStateContext.state &&
                appStateContext?.state.historyCurrentCount < appStateContext?.state.historyCount &&
                (appStateContext.state.chatHistoryMoreLoadingState === ChatHistoryLoadingState.Success &&
                appStateContext?.state.historyCurrentCount < appStateContext?.state.historyCount ? (
                    <div className="flex justify-center">
                        <a
                            className="gap-1 w-fit ease-linear transition-all duration-300 font-bold flex cursor-pointer text-center items-center text-lg text-jtc-pink uppercase tracking-[.15rem] whitespace-nowrap"
                            onClick={fetchMoreChatHistory}
                        >
                            <span className="text-[0.875rem]">
                                <span className="hover-underline hover:text-jtc-blue pb-[3px]">Load more</span>
                            </span>
                        </a>
                    </div>
                ) : (
                    <div className="flex justify-center text-[0.875rem] font-semibold uppercase tracking-[.15rem] text-jtc-pink mx-auto w-fit whitespace-nowrap">
                        <a className="gap-1 w-fit font-bold flex text-center items-center text-lg whitespace-nowrap">
                            <span className="flex justify-center items-center h-[26.67px] w-[26.67px]">
                                <Spinner style={{ display: "block", verticalAlign: "middle" }} styles={spinnerStyles} />
                            </span>
                            <span className="text-[0.875rem]">
                                <span className="">Loading</span>
                            </span>
                        </a>
                    </div>
                ))}
        </>
    );
};

export default ChatHistoryList;
