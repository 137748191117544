import { Outlet, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import logo from "../../assets/logo.svg";

const Layout = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    return (
        <div className="flex flex-col h-full">
            <header
                className={
                    "relative bg-jtc-white text-jtc-purple z-[100] before:block before:w-full before:mb-0 before:h-2.5 before:bg-gradient-to-r before:from-jtc-pink before:to-jtc-purple before:content-[''] " +
                    (activeAccount ? "hidden lg:block" : "block") +
                    " md:top-0"
                }
                role={"banner"}
            >
                <div className="relative items-center flex justify-between h-[50px] border-0">
                    <Link to="/" className="items-center text-jtc-purple flex gap-7 pl-4 no-underline md:pl-16 md:pr-10">
                        <img src={logo} alt="ChatJTC logo" width="80px" height="30px" className="h-[30px] w-auto" />
                        <h3 className={"tracking-[0.2rem] font-black text-base hidden md:block"}>ChatJTC</h3>
                    </Link>
                    <div className={"items-center tracking-[0.2rem] hidden md:flex md:pr-16 md:pl-10 uppercase no-underline font-black text-base gap-4 pr:4"}>
                        <div className={""}>Stronger Together</div>
                    </div>
                    <div className="flex items-center tracking-[0.2rem] font-black text-base gap-4 pr-4 no-underline md:hidden">
                        <div>ChatJTC</div>
                    </div>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
