const BACKEND_URI = "";

import { ChatRequest, HistoryRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, Assistant } from "./models";

function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };

    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

function getFormHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {};

    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

export async function chatApi(request: ChatRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string, idToken: string | undefined): string {
    const url = `${BACKEND_URI}/content/${citation}`;
    const tokenParam = idToken ? `?bt=${idToken}` : ''
    
    // Check if the URL contains a hash
    const hashIndex = url.indexOf('#');

    if (hashIndex > -1) {
        // URL has a hash, insert the token before the hash
        const baseUrl = url.substring(0, hashIndex);
        const hashPart = url.substring(hashIndex);
        return `${baseUrl}${tokenParam}${hashPart}`;
    }
    return `${url}${tokenParam}`;
}

export async function getDocumentText(formData: FormData, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/document/get_text`, {
        method: "POST",
        headers: getFormHeaders(idToken),
        body: formData
    });
}

export async function generateHistory(request: HistoryRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/history/generate`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export const historyCount = async (idToken: string | undefined, assistant?: string): Promise<number> => {
    const response = await fetch("/history/count", {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify({
            assistant: assistant
        }),
    })
        .then(async res => {
            if (!res) {
                return 0;
            }
            const payload = await res.json();
            let count: number = 0;
            if (payload?.conversation_count) {
                count = payload.conversation_count;
            }
            return count;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return 0;
        });
    return response;
};

export const historyList = async (idToken: string | undefined, offset?: number, assistant?: string): Promise<Conversation[] | null> => {
    const response = await fetch("/history/list", {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify({
            offset: offset,
            assistant: assistant
        }),
    })
        .then(async res => {
            const payload = await res.json();
            if (!Array.isArray(payload)) {
                console.error("There was an issue fetching your data.");
                return null;
            }
            const conversations: Conversation[] = await Promise.all(
                payload.map(async (conv: any) => {
                    let convMessages: ChatMessage[] = [];
                    convMessages = await historyRead(conv.id, idToken)
                        .then(res => {
                            return res;
                        })
                        .catch(err => {
                            console.error("error fetching messages: ", err);
                            return [];
                        });
                    const conversation: Conversation = {
                        id: conv.id,
                        assistant: conv.assistant,
                        title: conv.title,
                        messages: convMessages,
                        date: conv.updatedAt
                    };
                    return conversation;
                })
            );
            return conversations;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return null;
        });

    return response;
};

export const historyRead = async (convId: string, idToken: string | undefined): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: getHeaders(idToken)
    })
        .then(async res => {
            if (!res) {
                return [];
            }
            const payload = await res.json();
            let messages: ChatMessage[] = [];
            if (payload?.chat_messages) {
                payload.chat_messages.forEach((msg: any) => {
                    const message: ChatMessage = {
                        id: msg.id,
                        message: msg.message,
                        context: msg.context,
                        date: msg.createdAt
                    };
                    messages.push(message);
                });
            }
            return messages;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return [];
        });
    return response;
};

export const historyEnsure = async (idToken: string | undefined): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
        headers: getHeaders(idToken)
    })
        .then(async res => {
            let respJson = await res.json();
            let formattedResponse;
            if (respJson.message) {
                formattedResponse = CosmosDBStatus.Working;
            } else {
                if (res.status === 500) {
                    formattedResponse = CosmosDBStatus.NotWorking;
                } else {
                    formattedResponse = CosmosDBStatus.NotConfigured;
                }
            }
            if (!res.ok) {
                return {
                    cosmosDB: false,
                    status: formattedResponse
                };
            } else {
                return {
                    cosmosDB: true,
                    status: formattedResponse
                };
            }
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return {
                cosmosDB: false,
                status: err
            };
        });
    return response;
};

export const assistantsEnsure = async (idToken: string | undefined): Promise<CosmosDBHealth> => {
    const response = await fetch("/assistants/ensure", {
        method: "GET",
        headers: getHeaders(idToken)
    })
        .then(async res => {
            let respJson = await res.json();
            let formattedResponse;
            if (respJson.message) {
                formattedResponse = CosmosDBStatus.Working;
            } else {
                if (res.status === 500) {
                    formattedResponse = CosmosDBStatus.NotWorking;
                } else {
                    formattedResponse = CosmosDBStatus.NotConfigured;
                }
            }
            if (!res.ok) {
                return {
                    cosmosDB: false,
                    status: formattedResponse
                };
            } else {
                return {
                    cosmosDB: true,
                    status: formattedResponse
                };
            }
        })
        .catch(err => {
            console.error("There was an issue fetching your assistants.");
            return {
                cosmosDB: false,
                status: err
            };
        });
    return response;
};

export const assistantsList = async (idToken: string | undefined): Promise<Assistant[] | null> => {
    const response = await fetch("/assistants/list", {
        method: "GET",
        headers: getHeaders(idToken)
    })
        .then(async res => {
            const payload = await res.json();
            if (!Array.isArray(payload)) {
                console.error("There was an issue fetching your assistants.");
                return null;
            }
            const assistants: Assistant[] = await Promise.all(
                payload.map(async (assist: any) => {
                    const assistant: Assistant = {
                        assistant: assist.assistant,
                        title: assist.title,
                        examples: assist.examples,
                        type: assist.type,
                        short_title: assist.short_title,
                        url: assist.url,
                        attachments: assist.attachments
                    };
                    return assistant;
                })
            );
            return assistants;
        })
        .catch(err => {
            console.error("There was an issue fetching your assistants.");
            return null;
        });

    return response;
};
