import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";

interface Props {
    message: string;
    files?: string[];
}

export const UserChatMessage = ({ message, files }: Props) => {
    const appStateContext = useContext(AppStateContext);
    return (
        <div className="w-full">
            <div className={"transition-[padding] duration-200 py-[18px] px-4 text-base m-auto " + (appStateContext?.state.isChatHistoryOpen ? "" : "")}>
                <div className="mx-auto flex flex-1 text-base gap-4 lg:gap-6 lg:max-w-3xl">
                    <div className="flex w-full flex-col gap-1 items-end">
                        {files &&
                            files.map(file => (
                                <div className="relative max-w-[70%] rounded-lg bg-jtc-pink pl-10 pr-5 py-2.5 text-jtc-white">
                                    <span className="material-symbols-outlined text-[1.25rem] absolute bottom-[.75rem] left-4">attach_file</span>
                                    <span>{file}</span>
                                </div>
                            ))}
                        <div className="relative max-w-[70%] rounded-lg bg-jtc-pink px-5 py-2.5 text-jtc-white ">{message}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
