import { useContext } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import { marked, MarkedOptions } from "marked";
import { AppStateContext } from "../../state/AppProvider";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
};

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    onCitationClicked: (citationFilePath: string, citationTitle: string) => void,
    streamingDot: string
): HtmlParsedAnswer {
    const appStateContext = useContext(AppStateContext);
    const citations: string[] = [];
    const followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content);
        return "";
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    // Wrap incomplete tables during streaming first
    parsedAnswer = parsedAnswer.replace(/<table\b[^>]*>((?:(?!<\/table>)[\s\S])*?)$/g, match => {
        return renderToStaticMarkup(
            <div style={{ whiteSpace: "nowrap", overflowX: "auto", paddingBottom: "0.5rem" }} dangerouslySetInnerHTML={{ __html: match }} />
        );
    });

    // Then, wrap completed tables
    parsedAnswer = parsedAnswer.replace(/<table\b[^>]*>([\s\S]*?)<\/table>/g, match => {
        return renderToStaticMarkup(
            <div style={{ whiteSpace: "nowrap", overflowX: "auto", paddingBottom: "0.5rem" }} dangerouslySetInnerHTML={{ __html: match }} />
        );
    });

    const parts = parsedAnswer.split(/\[(.*?\.(?:pdf|mp4)[^\]]*)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part, appStateContext?.state.token?.accessToken);
            let title = part.replace(/\.\w+#page=/, " - Page ");
            let citationTitle = part.replace(/\.\w+#page=.*$/, "");

            return renderToStaticMarkup(
                <a className="supContainer px-1 text-jtc-purple" onClick={() => onCitationClicked(path, citationTitle)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    parsedAnswer = fragments.join("");

    const options: MarkedOptions = { async: false };
    parsedAnswer = marked.parse(parsedAnswer, options) as string;
    parsedAnswer = parsedAnswer.replace(
        /<pre><code class="language-(.*?)">/g,
        '<pre><div class="relative px-4 py-2 text-xs font-sans bg-jtc-grey text-jtc-purple font-semibold">$1</div><div class="overflow-y-auto p-4 text-left bg-jtc-very-dark-grey text-jtc-white"><code class="language-$1">'
    );
    parsedAnswer = parsedAnswer.replace(/<pre><code>/g, '<pre><div class="overflow-y-auto p-4 text-left bg-jtc-very-dark-grey text-jtc-white"><code>');
    parsedAnswer = parsedAnswer.replace(/<\/code><\/pre>/g, "</code></div></pre>");

    parsedAnswer = parsedAnswer.replace(/<ol\b[^>]*>((?:(?!<\/ol>)[\s\S])*?)<\/ol>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<ul\b[^>]*>((?:(?!<\/ul>)[\s\S])*?)<\/ul>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/p>\s*<ol>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/p>\s*<ul>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/ol>\s*<p>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/ul>\s*<p>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/h\d+>\s*<ol>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/h\d+>\s*<ul>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/ol>\s*<h\d+>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/ul>\s*<h\d+>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/li>\s*<li>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/ul>\s*<\/li>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/<\/li>\s*<\/ul>/g, match => {
        return match.replace(/>\s*</g, "><");
    });
    parsedAnswer = parsedAnswer.replace(/(<\/\w+>)\s*$/, streamingDot + "$1");

    return {
        answerHtml: parsedAnswer,
        citations,
        followupQuestions
    };
}
