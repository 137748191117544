import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const ButtonTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#ffffff"
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ffffff",
        color: "#65216e",
        fontFamily: "Bliss, Lato, Helvetica Neue, Helvetica, Arial, sans-serif",
        fontSize: "0.75rem",
        textTransform: "uppercase",
        letterSpacing: "0.15rem",
        fontWeight: "900",
        borderRadius: ".5rem",
        padding: "9.875px 15px",
        height: "40px"
    }
}));
