import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { Sidebar } from "../Sidebar";

interface Props {
    onNewClick: () => void;
    newDisabled?: boolean;
    onHistoryClick: () => void;
}

export const DefaultSidebar = ({ onNewClick, newDisabled, onHistoryClick }: Props) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div
            className={
                "transition-sidebar flex grow-0 shrink-0 overflow-x-hidden bg-jtc-grey" +
                (appStateContext?.state.isChatHistoryOpen ? " basis-[300px]" : " basis-0")
            }
        >
            <Sidebar onNewClick={onNewClick} newDisabled={newDisabled} onHistoryClick={onHistoryClick} />
        </div>
    );
};
