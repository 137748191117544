import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { registerIcons } from "@fluentui/react/lib/Styling";
import { Send28Filled } from "@fluentui/react-icons";
import { GPT4SparkleIcon, JTCShieldIcon } from "./components/ModelSelector/ModelSelectorIcons";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import LoginPage from "./pages/login/LoginPage";
import { AppStateProvider } from "./state/AppProvider";

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
    const [isActiveUser, setIsActiveUser] = useState(false);
    const [isMsalInitialized, setIsMsalInitialized] = useState(false); // New state for MSAL initialization

    useEffect(() => {
        const initMsal = async () => {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);
                setIsActiveUser(true);
            }

            const callback = (event: any) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const account = event.payload as AccountInfo;
                    msalInstance.setActiveAccount(account);
                    setIsActiveUser(true);
                }
            };
            msalInstance.addEventCallback(callback);

            setIsMsalInitialized(true); // Set MSAL as initialized after setup
        };

        initMsal();
    }, []);

    useEffect(() => {
        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
            msalInstance.setActiveAccount(msalInstance.getActiveAccount());
            setIsActiveUser(true);
        }

        const callback = (event: any) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const account = event.payload as AccountInfo;
                msalInstance.setActiveAccount(account);
                setIsActiveUser(true);
            }
        };
        msalInstance.addEventCallback(callback);
        return () => {};
    }, []);

    const activeLayout = <Layout />;
    const activeLayoutChild = isActiveUser ? (
        <AppStateProvider>
            <Chat />
        </AppStateProvider>
    ) : (
        <LoginPage />
    );

    const router = createBrowserRouter([
        {
            path: "/",
            element: activeLayout,
            children: [
                {
                    index: true,
                    element: activeLayoutChild
                },
                {
                    path: ":assistantId",
                    element: activeLayoutChild
                },
                {
                    path: "*",
                    lazy: () => import("./pages/NoPage")
                }
            ]
            // children: isActiveUser
            //     ? [
            //           {
            //               index: true,
            //               element: (
            //                   <AppStateProvider>
            //                       <Chat />
            //                   </AppStateProvider>
            //               )
            //           },
            //           {
            //               path: "c/:assistantId",
            //               element: (
            //                   <AppStateProvider>
            //                       <Chat />
            //                   </AppStateProvider>
            //               )
            //           },
            //           {
            //               path: "*",
            //               lazy: () => import("./pages/NoPage")
            //           }
            //       ]
            //     : [
            //           {
            //               index: true,
            //               element: <LoginPage />
            //           },
            //           {
            //               path: "*",
            //               lazy: () => import("./pages/NoPage")
            //           }
            //       ]
        }
    ]);

    return (
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <RouterProvider router={router} />
            </MsalProvider>
        </React.StrictMode>
    );
};

initializeIcons();

registerIcons({
    icons: {
        Send28Filled: <Send28Filled />,
        GPT4Sparkle: <GPT4SparkleIcon />,
        JTCShield: <JTCShieldIcon />
    }
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
